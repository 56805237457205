// Imports
// ------
import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import jsonUnsorted from './AllMaterials.json';
import PopUp from './PopUp';
import Download from './Download';

// Styles
// ------
import * as CSS from './MaterialLibrary.module.scss';

// Component
// ------
function MaterialLibrary() {
	const [activeCategory, setActiveCategory] = useState(null);
	const [activeSubCategory, setActiveSubCategory] = useState(null);
	const [activeMaterial, setActiveMaterial] = useState(null);
	const [activePass, setActivePass] = useState(null);
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const [currentCategoryMaterialArray, setCurrentCategoryMaterialArray] =
		useState(null);
	const [scrollMaterials, setScrollMaterials] = useState(0);

	// Get unique categories into an array and display how many each has
	const categories = [];
	// Sort the material array by favourites first
	const json = jsonUnsorted.sort(
		(a, b) => (b?.Favourite || false) - (a?.Favourite || false)
	);
	json.forEach((item) => {
		const materialClass = item?.ClassificationAttributes?.Class?.replaceAll(
			'\n',
			''
		)?.toLowerCase();
		// If is first of category, add it and set amount to 1
		if (
			materialClass &&
			item?.WebReferences?.Dato_ball &&
			item?.Published === true &&
			!categories.find((i) => i.name === materialClass)
		) {
			categories.push({
				name: materialClass,
				amount: 1,
				subclasses: [
					item?.ClassificationAttributes?.SubClass
						? materialClass + item?.ClassificationAttributes?.SubClass
						: '',
				],
			});
		}
		// If not, update amount
		else if (
			materialClass &&
			item?.WebReferences?.Dato_ball &&
			item?.Published === true
		) {
			categories.find((i) => i.name === materialClass).amount =
				categories.find((i) => i.name === materialClass).amount + 1;
			if (
				item?.ClassificationAttributes?.SubClass &&
				!categories
					.find((i) => i.name === materialClass)
					.subclasses.includes(
						materialClass + item?.ClassificationAttributes?.SubClass
					)
			) {
				categories
					.find((i) => i.name === materialClass)
					.subclasses.push(
						materialClass + item?.ClassificationAttributes?.SubClass
					);
			}
		}
	});

	// used to filter materials
	useEffect(() => {
		let array = [];
		setPage(1);

		if (activeSubCategory) {
			json.forEach((item) => {
				const materialClass = item?.ClassificationAttributes?.Class?.replaceAll(
					'\n',
					''
				)?.toLowerCase();
				console.log(materialClass + item?.ClassificationAttributes?.SubClass);
				console.log(activeSubCategory);
				if (
					activeSubCategory ===
						materialClass + item?.ClassificationAttributes?.SubClass &&
					item?.WebReferences?.Dato_ball
				) {
					array.push(item);
				}
			});
		} else if (
			activeCategory &&
			activeCategory !== 'All' &&
			!activeSubCategory
		) {
			json.forEach((item) => {
				if (
					activeCategory ===
						item?.ClassificationAttributes?.Class?.replaceAll(
							'\n',
							''
						)?.toLowerCase() &&
					item?.WebReferences?.Dato_ball
				) {
					array.push(item);
				}
			});
		} else if (activeCategory === 'All') {
			json.forEach((item) => {
				if (item?.WebReferences?.Dato_ball) {
					array.push(item);
				}
			});
		}

		setCurrentCategoryMaterialArray(array);
	}, [activeCategory, activeSubCategory]);

	// Password logic
	const [passwordCorrect, setPasswordCorrect] = useState(null);
	const [checkLocalStorage, setCheckLocalStorage] = useState(false);
	// Check if user has already set password once
	useEffect(() => {
		if (localStorage.getItem('passwordSet') === 'true') {
			setPasswordCorrect(true);
			setActiveCategory('All');
		}
		setCheckLocalStorage(true);
	}, []);
	// Function that happens on password enter
	function enter() {
		const passWord = 'thislookssoreal';
		const input = document.getElementById('pass').value;

		if (passWord === input) {
			setPasswordCorrect(true);
			setActiveCategory('All');
			localStorage.setItem('passwordSet', 'true');
		} else {
			setPasswordCorrect(false);
		}
	}

	// Pagination
	const [page, setPage] = useState(1);

	return (
		<section className={`${CSS.section} `}>
			<div className={CSS.sidebar}>
				<div>
					<div className={CSS.logoAndMobileMenuButton}>
						<img
							className={CSS.sidebarLogo}
							src='/material-library/mxr-scanned-assets.svg'
							alt='MXR Scanned Assets'
							width='156'
							height='37'
							loading='eager'
						/>
						<button
							aria-label='Open header menu'
							className={`${CSS.mobileMenuButton} ${
								mobileMenuOpen ? CSS.menuOpen : ''
							}`}
							onClick={() =>
								setMobileMenuOpen(mobileMenuOpen ? false : true)
							}></button>
					</div>
					<div className={CSS.sidebarLine} />
					<ul
						className={`${mobileMenuOpen ? '' : CSS.showOnlyActiveCategory} ${
							CSS.materialMenu
						}`}>
						<li className={activeCategory === 'All' ? CSS.activeCategory : ''}>
							<button
								onClick={() => {
									passwordCorrect ? setActiveCategory('All') : null;
									setActiveMaterial(null);
									setMobileMenuOpen(false);
								}}>
								All
								<span>{json.length}</span>
							</button>
						</li>
						{categories.map((loop, i) => (
							<li
								key={i}
								className={
									activeCategory === loop.name ? CSS.activeCategory : ''
								}>
								<button
									onClick={() => {
										activeCategory === loop.name
											? setMobileMenuOpen(true)
											: setMobileMenuOpen(false);
										passwordCorrect ? setActiveCategory(loop.name) : null;
										setActiveSubCategory(null);
										setActiveMaterial(null);
									}}>
									{loop.name}
									<span>{loop.amount}</span>
								</button>
								<ul>
									{loop.subclasses.map(
										(subclass, s) =>
											subclass !== '' && (
												<li
													key={s}
													className={
														activeSubCategory === subclass
															? CSS.activeSubclass
															: ''
													}>
													<button
														onClick={() => {
															setMobileMenuOpen(false);
															setActiveSubCategory(subclass);
															setActiveMaterial(null);
														}}>
														{subclass.replace(loop.name, '')}
													</button>
												</li>
											)
									)}
								</ul>
							</li>
						))}
					</ul>
				</div>
				<Link to='/' className={CSS.goBack}>
					<img
						src='/material-library/go-back.svg'
						alt='Go back to homepage'
						loading='lazy'
						width='18'
						height='18'
					/>
					Go Back
				</Link>
			</div>

			<div
				className={`${CSS.main} ${
					checkLocalStorage &&
					!activeCategory &&
					!activeSubCategory &&
					!activeMaterial &&
					passwordCorrect !== true
						? CSS.backgroundActive
						: ''
				}`}>
				{checkLocalStorage &&
					!activeCategory &&
					!activeSubCategory &&
					!activeMaterial &&
					passwordCorrect !== true && (
						<div className={CSS.introduction}>
							<h1>MATERIAL LIBRARY (BETA) ACCESS</h1>
							<p style={{ margin: '4.25rem 0 2rem 0' }}>
								Browse the M-XR material library (beta). Download and test the
								quality of the materials created using the M-XR methodology.
								This method is the first universal solution that allows for
								automatic and accurate measurement of real-world material
								properties.{' '}
							</p>
							<div className={CSS.scannedMaps}>
								<div>
									<h2>Captured Maps Available:</h2>
									<ul className={CSS.available}>
										<li>Diffuse</li>
										<li>Roughness</li>
										<li>Specular</li>
										<li>Normal</li>
									</ul>
								</div>
								<div>
									<h2>Captured Maps Coming Soon:</h2>
									<ul className={CSS.notAvailable}>
										<li>Displacement</li>
										<li>Metallic</li>
										<li>Specular Tint</li>
										<li>Material ID / Mask</li>
									</ul>
								</div>
							</div>
							<input
								type='password'
								className={CSS.password}
								id='pass'
								placeholder='ENTER PASSWORD'
								required
							/>
							{passwordCorrect === false && (
								<p style={{ color: 'red' }}>Password incorrect</p>
							)}
							<p className={CSS.legals}>
								<b>IMPORTANT NOTICE:</b> BY ENTERING THE PASSWORD YOU
								AUTOMATICALLY ACKNOWLEDGE AND ACCEPT THE{' '}
								<a
									href='https://m-xr.com/material-library-legal/'
									target='_blank'
									rel='noopener noreferrer'>
									TERMS AND CONDITIONS
								</a>{' '}
								OF M-XR LIMITED.
							</p>
							<div className={CSS.enterAndRequest}>
								<PopUp text='Request password' />
								<button className={CSS.enter} onClick={() => enter()}>
									Enter
								</button>
							</div>
						</div>
					)}

				{(activeCategory || activeSubCategory) && (
					<div
						className={CSS.materialList}
						style={{ display: activeMaterial ? 'none' : 'flex' }}>
						<ul className={CSS.materials}>
							{currentCategoryMaterialArray.map(
								(loop, i) =>
									i < page * 30 &&
									i > page * 30 - 31 && (
										<li key={i}>
											<img
												src={
													loop?.WebReferences?.Dato_ball +
													'?auto=format&q=80&w=400'
												}
												alt={`Sphere preview of the material ${loop.ScanName}`}
												loading='lazy'
												width='140'
												height='140'
											/>
											<button
												onClick={() => {
													passwordCorrect && setActiveMaterial(loop);
													setActivePass(null);
												}}>
												<span>{loop.ScanName}</span>
											</button>
										</li>
									)
							)}
						</ul>

						{Math.ceil(currentCategoryMaterialArray.length / 30) > 1 && (
							<nav className={CSS.pagination} aria-label='Pagination'>
								{[
									...Array(Math.ceil(currentCategoryMaterialArray.length / 30)),
								].map((loop, i) => (
									<button
										key={i}
										className={page === i + 1 ? CSS.activePagination : ''}
										onClick={() => {
											setPage(i + 1);
										}}>
										{i + 1}
									</button>
								))}
							</nav>
						)}
					</div>
				)}

				{activeMaterial && (
					<div className={CSS.activeMaterial}>
						<div className={CSS.previewImage}>
							<div className={CSS.previewImageWrapper}>
								<p>
									{activePass !== null
										? activeMaterial?.ClassificationAttributes?.Pass[activePass]
										: 'Detailed'}
								</p>
								<img
									src={
										activeMaterial?.WebReferences?.Dato_render +
										'?auto=format&q=85&w=1200&h=1200&fit=crop'
									}
									alt=''
									width='1200'
									height='1200'
									loading='eager'
									fetcpPriority='high'
								/>
								{activePass !== null && (
									<img
										src={
											activeMaterial?.WebReferences?.Dato_thumbnails[
												activePass
											] + '?auto=format&q=85&w=800'
										}
										alt=''
										width='800'
										height='800'
										className={CSS.activePass}
										loading='eager'
										fetcpPriority='high'
									/>
								)}
							</div>
							{activeMaterial?.ClassificationAttributes?.Pass?.length > 0 && (
								<div className={CSS.attributeMaterials}>
									{scrollMaterials !== 0 && (
										<button
											className={CSS.previousMaterials}
											onClick={() => setScrollMaterials(scrollMaterials + 9.5)}>
											<img
												src='/material-library/material-arrow.svg'
												alt='Previous materials'
												width='42'
												height='42'
												loading='lazy'
											/>
										</button>
									)}
									<ul
										style={{ transform: `translateX(${scrollMaterials}rem)` }}>
										<li className={activePass === null ? CSS.active : ''}>
											<button onClick={() => setActivePass(null)}>
												<img
													src={
														activeMaterial?.WebReferences?.Dato_render +
														'?auto=format&q=85&w=90&h=90'
													}
													alt={`Preview image of this material's detailed render`}
													width='60'
													height='60'
													loading='eager'
												/>
												<span>Detailed</span>
											</button>
										</li>
										{activeMaterial?.ClassificationAttributes?.Pass?.map(
											(loop, i) =>
												activeMaterial?.WebReferences?.Dato_thumbnails[i] && (
													<li
														key={i}
														className={activePass === i ? CSS.active : ''}>
														<button
															onClick={() =>
																activePass === i
																	? setActivePass(null)
																	: setActivePass(i)
															}>
															<img
																src={
																	activeMaterial?.WebReferences
																		?.Dato_thumbnails[i] +
																	'?auto=format&q=85&w=90&h=90'
																}
																alt={`Preview image of this material's ${loop}`}
																width='60'
																height='60'
																loading='eager'
															/>
															<span>{loop}</span>
														</button>
													</li>
												)
										)}
									</ul>
									{(scrollMaterials / 9.5) * -1 + 5 <
										activeMaterial?.ClassificationAttributes?.Pass?.length +
											1 && (
										<button
											className={CSS.nextMaterials}
											onClick={() => setScrollMaterials(scrollMaterials - 9.5)}>
											<img
												src='/material-library/material-arrow.svg'
												alt='Next materials'
												width='42'
												height='42'
												loading='lazy'
											/>
										</button>
									)}
								</div>
							)}
						</div>
						<div className={CSS.previewInformation}>
							<div>
								<h2>{activeMaterial.ScanName}</h2>
								{activeMaterial?.ClassificationAttributes?.Tags?.length > 0 &&
									activeMaterial?.ClassificationAttributes?.Tags[0] && (
										<>
											<h3>TAGS</h3>
											<div className={CSS.tags}>
												{activeMaterial?.ClassificationAttributes?.Tags?.map(
													(loop, i) => (
														<p key={i}>{loop}</p>
													)
												)}
											</div>
										</>
									)}
								<h3>INFO</h3>
								<div className={CSS.details}>
									{activeMaterial?.ImageAttributes?.SourceWidth &&
										activeMaterial?.ImageAttributes?.SourceHeight && (
											<p>
												<span>Resolution:</span>{' '}
												<span>
													{activeMaterial?.ImageAttributes?.SourceWidth} x{' '}
													{activeMaterial?.ImageAttributes?.SourceHeight}px
												</span>
											</p>
										)}
									{activeMaterial?.ImageAttributes?.RealWorldWidth &&
										activeMaterial?.ImageAttributes?.RealWorldHeight && (
											<p>
												<span>Real world scale:</span>{' '}
												<span>
													{activeMaterial?.ImageAttributes?.RealWorldWidth} x{' '}
													{activeMaterial?.ImageAttributes?.RealWorldHeight}cm
												</span>
											</p>
										)}
									{activeMaterial?.ClassificationAttributes?.Class && (
										<p>
											<span>Class:</span>{' '}
											<span>
												{activeMaterial?.ClassificationAttributes?.Class}
											</span>
										</p>
									)}
									{activeMaterial?.ClassificationAttributes?.SubClass && (
										<p>
											<span>Sub Class:</span>{' '}
											<span>
												{activeMaterial?.ClassificationAttributes?.SubClass}
											</span>
										</p>
									)}
									{activeMaterial?.PipelineAttributes?.Tilable !== '' && (
										<p>
											<span>Tiled:</span>{' '}
											<span>
												{activeMaterial?.PipelineAttributes?.Tilable
													? 'True'
													: 'False'}
											</span>
										</p>
									)}
								</div>
								<h3 style={{ color: 'inherit' }}>MEASURED PROPERTIES</h3>
								<p className={CSS.measuredProperties}>
									{activeMaterial?.ClassificationAttributes?.Pass.join(', ')}
								</p>
							</div>
							<div className={CSS.links}>
								{/* <select name="cars">
                                    <option value="exr">EXR</option>
                                    <option value="saab">Saab</option>
                                    <option value="mercedes">Mercedes</option>
                                    <option value="audi">Audi</option>
                                </select> */}
								<Download href={activeMaterial.WebReferences.S3} />
							</div>
						</div>
						<button
							className={CSS.close}
							onClick={() => {
								setActiveMaterial(null);
								setActivePass(null);
							}}>
							&#10005;
						</button>
					</div>
				)}
			</div>
		</section>
	);
}

export default MaterialLibrary;
